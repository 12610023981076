<template>
    <div class="warpper">
        <div class="page-title flex justify-between align-center">
            <div class="title">选择模板</div>
            <router-link to="/organiz/apply/publish">
                <el-button class="publish" type="primary" @click="toBack">返回</el-button>
            </router-link>
        </div>
        <div class="page-list flex flex-wrap">
            <div class="model-item" v-for="(item,index) in lists" :key="index">
                <el-image class="item-image" :src="item.thumb" fit="contain"></el-image>
                <div class="item-title">{{item.name}}</div>
                <div class="flex align-center justify-around margin-tb">
                    <el-button class="item-operate active" @click="selectModel(item)">使用</el-button>
                    <el-link class="item-operate" :href="item.preview_url" target="_blank" :underline="false">预览
                    </el-link>
                </div>
            </div>
        </div>
        <el-dialog
                title="提交申请"
                :visible.sync="applyDialogVisible"
                width="30%"
                class="dialog-container"
                center>
            <el-form class="info width-100 margin-top" ref="ruleForm" :model="ruleForm" :rules="rules"
                     labelPosition="left" labelWidth="100px">
                <el-form-item label="机构名称" prop="compony" class="input-container">
                    <el-input v-model="ruleForm.compony" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone" class="input-container">
                    <el-input v-model="ruleForm.phone" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="name" class="input-container">
                    <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="网站域名" prop="domain" class="input-container">
                    <el-radio-group v-model="ruleForm.domain">
                        <el-radio :label="0">无</el-radio>
                        <el-radio :label="1">有</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="网站空间" prop="size" class="input-container">
                    <el-radio-group v-model="ruleForm.size">
                        <el-radio :label="0">无</el-radio>
                        <el-radio :label="1">有</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div class="dialog-footer flex align-center justify-around">
                    <el-button class="operate" @click="applyDialogVisible = false">取消</el-button>
                    <el-button class="operate yellow" @click="submitForm('ruleForm')">提交</el-button>
                </div>
            </el-form>

        </el-dialog>

        <el-dialog
                title="温馨提示"
                :visible.sync="resultDialogVisible"
                width="22%"
                class="dialog-container"
                center>
            <span class="result-content">您选择的模板是<span>{{cur_item.name}}</span>，我们将在一天内帮您配置完成，
                如有任何问题可以直接电话进行沟通，专业人员一对一为您服务，服务电话：{{service_phone}}</span>
            <span slot="footer" class="dialog-footer">
                <el-button class="operate yellow" @click="resultDialogVisible = false">我知道了</el-button>
              </span>
        </el-dialog>

    </div>
</template>

<script>
    import rules from '@/utils/rules'
    import {setPath, service_phone} from '@/utils/utils'
    import {getWebModelList, userApplyWeb, getApplyWebInfo} from '@/api/organiz'
//    import {filePath} from "@/config/index";

    export default {
        components: {},
        name: "apply",
        data() {
            return {
                id:'',
                lists: [],
                page: 1,
                limit: 1000,
                total: 0,

                service_phone: service_phone,

                cur_id: '',
                cur_item: '',//选择的模版
                ruleForm: {
                    compony: '',
                    name: '',
                    phone: '',
                    domain: 0,
                    size: 0
                },
                rules: {
                    compony: [
                        {required: true, message: '请输入机构名称', trigger: 'blur'}
                    ],
                    phone: [
                        {required: true, message: '请输入手机号', trigger: 'blur'},
                        rules.phone
                    ],
                    name: [
                        {required: true, message: '请输入联系人', trigger: 'blur'}
                    ],
                    domain: [
                        {required: true, message: '请选择是否有网站域名', trigger: 'change'},
                    ],
                    size: [
                        {required: true, message: '请选择是否有网站空间', trigger: 'blur'}
                    ],
                },
                applyDialogVisible: false,
                resultDialogVisible: false
            }
        },
        computed: {
            paths() {
                return [...setPath('自助建站申请', '/organiz/apply/list'), ...setPath('选择模版', '/organiz/apply/publish')]
            },
            user() {
                return this.$store.state.user
            }
        },
        created() {
            this.$emit('setIndex', 0, this.paths)
            this.id = this.$route.query.id || ''
            this.init()

        },
        methods: {
            //  页面加载时请求
            async init() {
                let _data = await getWebModelList(this.page, this.limit)
                if (_data.status === 200) {
//                    _data.data.map(item=>{
//                        item.thumb = filePath + item.thumb
//                    })
                    this.lists = _data.data
                    this.total = _data.total
                    if (this.id) this.getApplyWebInfo()
                }
            },
            //  如果有ID为修改，先获取信息
            async getApplyWebInfo() {
                let _data = await getApplyWebInfo(this.id)
                if (_data.status === 200) {
                    this.cur_id = _data.data.template_id
                    this.cur_item = this.lists.find(item=>{ return item.id === _data.data.template_id}) || ''
                    this.ruleForm = {
                        compony: _data.data.compony_name,
                        name: _data.data.name,
                        phone: _data.data.phone,
                        domain: _data.data.space_ok,
                        size: _data.data.domain_ok
                    }
                    this.applyDialogVisible = true
                }
            },
            selectModel(item) {
                this.cur_id = item.id
                this.cur_item = item
                this.ruleForm.compony = this.user.morgname
                this.applyDialogVisible = true
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.apply(formName)
                    }
                });
            },
            async apply(formName) {
                let _data = await userApplyWeb(this.id,this.cur_id,this.ruleForm.compony,
                    this.ruleForm.name,this.ruleForm.phone,
                    this.ruleForm.size,this.ruleForm.domain)
                if (_data.status === 200) {
                    this.applyDialogVisible = false
                    this.resultDialogVisible = true
                    this.$refs[formName].resetFields()
                } else {
                    this.$message.error(_data.msg)
                }
            },
            toBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .warpper {
        flex: 1;
        .page-title {
            border-bottom: 1px solid #d2d2d2;
            min-height: 72px;
            .title {
                font-size: 20px;
                font-weight: bold;
                color: #606060;
                position: relative;
                padding-left: 15px;
                &:after {
                    position: absolute;
                    width: 7px;
                    height: 23px;
                    background-color: #0a2240;
                    border-radius: 3px;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .publish {
                width: 140px;
                height: 46px;
                background-image: linear-gradient(37deg, #ffb400 0%, #fc7802 100%),
                linear-gradient(#0a2140, #0a2140);
                background-blend-mode: normal, normal;
                border-radius: 23px;
                border: none;
            }
        }
        .page-list {
            .model-item {
                width: 362px;
                border: solid 1px #ebecee;
                box-shadow: 0px 6px 6px 1px rgba(199, 199, 199, 0.45);
                border-radius: 10px;
                margin-top: 20px;
                &:not(:nth-child(3n+1)) {
                    margin-left: 20px;
                }
                .item-image {
                    width: 100%;
                    height: 239px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    background: #ebecee;
                }
                .item-title {
                    font-size: 18px;
                    color: #333333;
                    margin-top: 22px;
                    text-align: center;
                }
                .item-operate {
                    width: 116px;
                    height: 43px;
                    line-height: 43px;
                    padding: 0;
                    background-color: #ffffff;
                    border-radius: 21px;
                    border: solid 1px #0a2240;
                    font-size: 16px;
                    color: #0a2240;
                    &.active {
                        background-color: #0a2240;
                        color: #ffffff;
                    }
                }
            }
        }
        .result-content {
            font-size: 16px;
            line-height: 36px;
            color: #333333;
            span {
                color: #3381c3;
            }
        }

        .operate {
            width: 200px;
            height: 54px;
            border-radius: 27px;
            font-size: 16px;
            line-height: 54px;
            padding: 0;
            background-color: #ffffff;
            border: solid 1px #0a2240;
            color: #0a2240;
            &.yellow {
                background-image: linear-gradient(37deg, #ffb400 0%, #fc7802 100%),
                linear-gradient(#3381c3, #3381c3);
                background-blend-mode: normal, normal;
                color: #ffffff;
                border: none
            }
        }
    }
</style>